var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-row',{attrs:{"cols":"yellowOuterBlock"}},[_c('b-col',{staticClass:"yellowBlock",attrs:{"cols":"12"}})],1),_c('b-row',{staticClass:"tourCard"},[_c('b-col',{staticClass:"courseimage-hide",staticStyle:{"padding":"0"},attrs:{"lg":"4"}},[_c('div',{staticClass:"over",style:({ 'background-image': 'url(' + 
            _vm.DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' +
            _vm.id +
            '/' +
            _vm.tm_params.course_code +
            '/' +
            _vm.tm_params.course_image_file_1            
          + ')' })})]),_c('b-col',{staticStyle:{"padding":"25px"},attrs:{"lg":"8"}},[_c('b-row',[_c('b-col',{staticClass:"dateandtime",attrs:{"lg":"7"}},[_c('h3',{staticClass:"tourDate"},[_vm._v(" "+_vm._s(_vm.tm_params.dates)+" ")]),_c('h3',{staticClass:"tourCourse"},[_c('span',{staticClass:"FullName"},[_vm._v(_vm._s(_vm.tm_params.full_name))]),_c('br'),_c('span',{staticClass:"tourCourseTwo"},[_vm._v(_vm._s(_vm.tm_params.course_name))])])])],1),_c('hr'),_c('b-row',{staticClass:"bottomsection-info"},[_c('b-col',{staticClass:"infoPad",attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[_c('p',{staticClass:"otherValue"},[_vm._v(" "+_vm._s(_vm.tm_params.rounds)+" ")]),_c('p',{staticClass:"otherInfo"},[_vm._v(" Number of Rounds ")])]),_c('b-col',{staticClass:"infoPad",attrs:{"cols":"12","lg":"4","md":"4","sm":"12"}},[(_vm.tm_params.total_prize_fund.length > 0)?_c('p',{staticClass:"otherValue"},[_vm._v(" "+_vm._s(_vm.tm_params.total_prize_fund)+" ")]):_vm._e(),(_vm.tm_params.total_prize_fund.length > 0)?_c('p',{staticClass:"otherInfo"},[_vm._v(" Prize Fund ")]):_vm._e()])],1),_c('b-row',[_c('b-col',{staticClass:"infoPad",attrs:{"cols":"12","lg":"7","md":"7","sm":"12","order":"2","order-sm":"2","order-md":"1","order-lg":"1"}},[_c('b-button',{staticClass:"tourBtn",attrs:{"to":{
                name: 'tournament',
                query: {
                id: _vm.id,
                code: _vm.tm_params.code,
                },
            }}},[_vm._v("TOURNAMENT INFORMATION")])],1),_c('b-col',{attrs:{"cols":"12","lg":"5","md":"5","sm":"12","order":"1","order-sm":"1","order-md":"1","order-lg":"2"}},[_c('b-img',{staticClass:"courseLogo",attrs:{"src":_vm.tm_params.sponsor_logo}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }