<template>
  <div>
    <div class="backgroundGrass-Image">
      <template v-if="this.$route.path == '/sunshine-development-tour'">
        <b-container class="topImage">
          <div class="SDTImage" v-bind:style="{ 'background-image': 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2025/03/Sunshine_Development_Tour_Logo-scaled.jpg' + ')' }">
          </div>
          <!-- <img class="SDTImage" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2025/03/Sunshine_Development_Tour_Logo-scaled.jpg"> -->
        </b-container>
      </template>
      <b-container class="outerBlock">
        <b-row cols="yellowOuterBlock" :class="{development : this.$route.path == '/sunshine-development-tour'}">
          <b-col cols="12" class="yellowBlock"></b-col>
        </b-row>
        <b-row class="tourCard">
          <b-col lg="4" style="padding:0;" class="courseimage-hide">
            <div class="over" v-bind:style="{ 'background-image': 'url(' + wpInfo.acf.tourninfo_course_image + ')' }">
            </div>
          </b-col>
          <b-col lg="8" style="padding: 25px;">
            <b-row>
              <b-col lg="7" class="dateandtime">
                <h3 class="tourDate" :class="{developmentColor : this.$route.path == '/sunshine-development-tour'}">
                  <!-- {{ tm_params.dates }} -->
                  {{ wpInfo.acf.tourninfo_date }}
                </h3>
                <h3 class="tourCourse">
                  <!-- {{ tm_params.full_name }} -->
                  <span class="FullName">{{ wpInfo.acf.tourninfo_full_name }}</span> 
                  <br />
                  <span class="tourCourseTwo">
                    <!-- {{ tm_params.course_name }} -->
                    {{ wpInfo.acf.tourninfo_course_name }}
                  </span>
                </h3>
              </b-col>
            </b-row>
            <hr />
            <b-row class="bottomsection-info">
              <b-col class="infoPad" cols="12" lg="4" md="4" sm="12">
                <p class="otherValue" :class="{developmentColor : this.$route.path == '/sunshine-development-tour'}">
                  <!-- {{ tm_params.rounds }} -->
                  {{ wpInfo.acf.tourninfo_rounds }}
                </p>
                <p class="otherInfo">
                  Number of Rounds
                </p>
              </b-col>
              <b-col class="infoPad" cols="12" lg="4" md="4" sm="12">
                <p
                  class="otherValue"
                  :class="{developmentColor : this.$route.path == '/sunshine-development-tour'}"
                >
                  <!-- {{ tm_params.total_prize_fund }} -->
                  {{ wpInfo.acf.tourninfo_prize_fund }}
                </p>
                <p class="otherInfo" v-if="wpInfo.acf.tourninfo_prize_fund.length > 0">
                  Prize Fund
                </p>
              </b-col>
            </b-row>
            <b-row>
              <template v-if="this.$route.path == '/sunshine-development-tour'">
                <b-col class="infoPad" cols="12" lg="4" md="4" sm="12" order="2" order-sm="2" order-md="1" order-lg="1">
                  <b-button :href="wpInfo.acf.tourninfo_url" target="_blank" class="tourBtn" :class="{buttonDevlopment : this.$route.path == '/sunshine-development-tour'}">Q-School Entry Form</b-button>
                </b-col>
                <b-col class="infoPad" cols="12" lg="4" md="4" sm="12" order="2" order-sm="2" order-md="1" order-lg="1">
                  <b-button :href="wpInfo.acf.tourninfo_portal_link" class="tourBtn" target="_blank" :class="{buttonDevlopment : this.$route.path == '/sunshine-development-tour'}">{{wpInfo.acf.tourninfo_portal_text}}</b-button>
                </b-col>
                <b-col cols="12" lg="4" md="4" sm="12" order="1" order-sm="1" order-md="1" order-lg="2" >
                  <b-img class="courseLogo" :src="wpInfo.acf.tourninfo_sponsor_logo"></b-img>
                </b-col>
              </template>
              <template v-else>
                <b-col class="infoPad" cols="12" lg="6" md="6" sm="12" order="2" order-sm="2" order-md="1" order-lg="1">
                  <b-button :to="wpInfo.acf.tourninfo_url" class="tourBtn" :class="{buttonDevlopment : this.$route.path == '/sunshine-development-tour'}">Q-School Entry Form</b-button>
                </b-col>
                <b-col class="infoPad" cols="12" lg="6" md="6" sm="12" order="2" order-sm="2" order-md="1" order-lg="1">
                  <b-button :href="wpInfo.acf.tourninfo_portal_link" class="tourBtn" target="_blank" :class="{buttonDevlopment : this.$route.path == '/sunshine-development-tour'}">{{wpInfo.acf.tourninfo_portal_text}}</b-button>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Sunshine Development Tour Section -->


    <b-container class="developementSec">
      <!-- Section One -->
      <h2 class="secTitle" v-html="wpInfo.acf.section_one_title"></h2>
      <p v-html="wpInfo.acf.section_one_description"></p>

      <!-- Section Two -->
      <h2 class="secTitle" v-html="wpInfo.acf.section_two_title"></h2>
      <p v-html="wpInfo.acf.section_two_description"></p>

      <!-- Section Three -->
      <h2 class="secTitle" v-html="wpInfo.acf.section_three_title"></h2>
      <b-row>
        <b-col>
          <p v-html="wpInfo.acf.section_three_description_left"></p>
        </b-col>
        <!-- <b-col>
          <p v-html="wpInfo.acf.section_three_description_right"></p>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col>
          <h2 class="secTitle">News</h2>
          <InterNews :cat="'19'" feat="false" :config="config" />
        </b-col>
      </b-row>
    </b-container>


    <!-- Sa-Open Section -->
    <b-container class="content" v-if="this.$route.path != '/sunshine-development-tour'">
      <h3 class="q-scool-top-title">
        {{ wpInfo.acf.title }}
      </h3>
      <div>
        <p class="description" v-html="wpInfo.acf.description"></p>
      </div>
      <h3 class="q-scool-top-title">
        {{ wpInfo.acf.latest_title }}
      </h3>
      <template v-if="this.$route.path == '/sa-open'">
        <InterNews :cat="'17'" feat="false" :config="config" />
      </template>
      <template v-else>
        <InterNews :cat="'7'" feat="false" :config="config" />
      </template>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import InterNews from "@/components/interNews.vue";
export default {
  name: "School Page",
  components: {
    InterNews,
  },
  props: ["config"],
  data() {
    return {
      wpInfo: [],
      wpInfoTitle: '',
      tournamentList: []
    };
  },
  metaInfo() {
    return {
      title: this.wpInfoTitle,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  created() {
    if (this.$route.path == '/sa-open') {
      const json = "wp/v2/pages/" + "38824?randomadd=" +
          new Date().getTime();
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.wpInfo = data;
          this.wpInfoTitle = data.title.rendered;
          console.log("this.wpInfoTitle")
          console.log(this.wpInfoTitle)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } else if(this.$route.path == '/sunshine-development-tour') {
      const json = "wp/v2/pages/" + "40368?randomadd=" +
          new Date().getTime() 
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.wpInfo = data;
          this.wpInfoTitle = data.title.rendered;
          console.log("this.wpInfoTitle")
          console.log(this.wpInfoTitle)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } else {
      const json = "wp/v2/pages/" + "37906?randomadd=" +
          new Date().getTime() 
      apiCall
        .wpInfo(json)
        .then(({ data }) => {
          this.wpInfo = data;
          this.wpInfoTitle = data.title.rendered;
          console.log("this.wpInfoTitle")
          console.log(this.wpInfoTitle)
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    }
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmSeasonBase();
      this.seasonCode = first_call.data.tm_params.season_code
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmHomepage(this.seasonCode);
      this.tournamentList = second_call.data.tmticx.tournaments.tournaments_entry;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>



<style scoped>
.SDTImage {
  height: 170px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  margin-bottom: 10px;
  background-color: #081f3f;
}
.topImage {
  padding-left: 0px;
  padding-right: 0px;
}
img.SDTImage {
  width: 100%;
  margin-bottom: 10px;
  height: 200px;
}
img.courseLogo {
  width: 100px;
  float: right;
  margin-top: 9px;
}
.developementSec {
  margin-bottom: 100px;
}
.row.row-cols-yellowouterblock.development {
  background-color: #09203f;
}
.developmentColor {
  color: #09203f!important;
}
.buttonDevlopment {
  background-color: #657d98!important;
}
.buttonDevlopment:hover {
  background-color: #09203f!important;
}
::v-deep h2.contactTitle {
    color: #006737;
    font-size: 16pt;
    margin-top: 30px;
    font-weight: bold;
}
h2.secTitle {
    color: #6c6c6c;
    font-weight: 700;
    font-size: 28px;
    margin-top: 2em;
    text-transform: capitalize;
}
::v-deep .pulse {
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 103, 55, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
::v-deep a.q-school-btn {
  background-color: #006737;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff!important;
  display: inline-block;
  width: auto;
  text-align: center;
  margin-top: 15px;
}
::v-deep a.q-school-btn:hover {
  background-color: #F2AF14;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: #fff!important;
  display: inline-block;
  width: auto;
  text-align: center;
  margin-top: 15px;
}
.backgroundGrass-Image {
  background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  background-repeat: no-repeat;
  padding-bottom: 7em;
  padding-top: 5em;
  background-size: cover;
}
.otherInfo {
  color: #939393;
  margin-bottom: 0;
  text-align: left;
  font-size: 9pt;
}
.row.row-cols-yellowouterblock {
  height: 20px;
  background-color: #F2AF14;
  border-radius: 10px 10px 0px 0px;
}
.otherValue {
  color: #F2AF14;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
}
.tourCourseTwo{
  color: #939393;
  font-size: 9pt;
  font-weight: 100;
  margin-top: 25px;
  display: block;
  font-weight: bold;
}
.tourCourse {
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
}
.FullName {
 
}
.tourDate {
  color: #f2af15;
  font-size: 13px;
  margin-bottom: 30px;
  margin-top: 25px;
  text-transform: capitalize;
}
.courseImg {
  width: 100%;
  min-height: 352px;
  border-radius: 0px 0px 0px 0px;
  min-width: 1050px;
}
.over {
  overflow: hidden;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.tourBtn {
  display: block;
  /* margin: auto; */
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 300;
  font-size: 11px;
  margin-top: 30px;
  height: 35px;
  line-height: 26px!important;
  text-transform: uppercase;
}
.tourCard {
  background-color: white;
  border-radius: 10px 0px 0px 0px;
}
.sectionBackground {
  background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  background-repeat: no-repeat;
  padding-bottom: 7em;
  padding-top: 5em;
  background-size: cover;
}
::v-deep p.description > h3 {
  font-size: 13px;
  color: #6C6C6C;
  font-weight: 100;
  text-align: left!important;
}
p.description {
  margin-top: 30px;
}
::v-deep .card-subtitle {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
::v-deep .postBtn {
  text-align: center;
  display: block;
  margin: auto;
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  /* height: 35px; */
  line-height: 18px;
}
::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0px;
  padding-top: 15px;
  box-shadow: 0px 0px 0px #00000029;
}
span.hozText > h3 {
  font-size: 15pt;
}
::v-deep span.hozText > h3 > strong {
  font-size: 15pt;
}
img.hozImg.card-img {
    border-radius: 0px;
}
span.hozText {
    font-size: 15pt;
    display: block;
    padding-left: 35px;
    padding-right: 30px;
}
.hozTop {
    border-radius: 10px;
}
h4.card-title {
    /* color: #026034; */
    color: #026034;
    font-weight: 700;
    font-size: 18pt;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-left: 25px;
}
.noTourns-q-school {
    margin-bottom: 4em;
    text-align: center;
    background: #f2af15;
    color: #fff;
    border-radius: 10px;
    padding: 15px;
    font-size: 10pt;
}
h3.q-scool-top-title {
    color: #6C6C6C;
    font-weight: bold;
    font-size: 21px;
    /* margin-bottom: 3em; */
    margin-top: 4em;
    text-transform: capitalize;
   
}
.title{
    padding: 50px 0 20px 0;
    font-size: 1.31rem;
    border-bottom: 1px solid #eaecef;
    color: #016837;
    font-weight: 600;
}
::v-deep  a{
    color: #f2af14!important;

}
.content{
    padding-bottom: 50px;
}
::v-deep .wpcf7-submit{
    width: 63%;
    background-color: #f2af14;
    color: #fff;
    border: 0;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
}
@media only screen and (max-width: 768px) {
  .backgroundGrass-Image {
    background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
    background-repeat: no-repeat;
    padding: 1em!important;
    background-size: cover;
  }
  .outerBlock {
    /* padding: 2em; */
  }
  h4.card-title {
    color: #026034;
    font-weight: 700;
    font-size: 18pt;
    margin-bottom: 3em;
    margin-top: 1em;
    padding-left: 0!important;
    text-align: center;
  }
  .over {
    overflow: hidden;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
  }
  .courseImg {
    width: 100%;
    min-height: 352px;
    border-radius: 0px 0px 0px 0px;
    min-width: 1050px;
  }
}
</style>