import { render, staticRenderFns } from "./scheduleInfo.vue?vue&type=template&id=245828a6&scoped=true"
import script from "./scheduleInfo.vue?vue&type=script&lang=js"
export * from "./scheduleInfo.vue?vue&type=script&lang=js"
import style0 from "./scheduleInfo.vue?vue&type=style&index=0&id=245828a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245828a6",
  null
  
)

export default component.exports