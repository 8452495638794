<template>
    <b-container>
        <b-row cols="yellowOuterBlock">
            <b-col cols="12" class="yellowBlock"></b-col>
        </b-row>
        <b-row class="tourCard">
            <b-col lg="4" style="padding:0;" class="courseimage-hide">
              <div class="over" v-bind:style="{ 'background-image': 'url(' + 
                DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' +
                id +
                '/' +
                tm_params.course_code +
                '/' +
                tm_params.course_image_file_1            
              + ')' }" >
              </div>
            </b-col>
            <b-col lg="8" style="padding: 25px;">
            <b-row>
                <b-col lg="7" class="dateandtime">
                <h3 class="tourDate">
                    {{ tm_params.dates }}
                </h3>
                <h3 class="tourCourse">
                    <span class="FullName">{{ tm_params.full_name }}</span>
                    <br />
                    <span class="tourCourseTwo">{{ tm_params.course_name }}</span>
                </h3>
                </b-col>
            </b-row>
            <hr />
            <b-row class="bottomsection-info">
                <b-col class="infoPad" cols="12" lg="4" md="4" sm="12">
                <p class="otherValue">
                    {{ tm_params.rounds }}
                </p>
                <p class="otherInfo">
                    Number of Rounds
                </p>
                </b-col>
                <b-col class="infoPad" cols="12" lg="4" md="4" sm="12">
                <p
                    class="otherValue"
                    v-if="tm_params.total_prize_fund.length > 0"
                >
                    {{ tm_params.total_prize_fund }}
                </p>
                <p class="otherInfo" v-if="tm_params.total_prize_fund.length > 0">
                    Prize Fund
                </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="infoPad" cols="12" lg="7" md="7" sm="12" order="2" order-sm="2" order-md="1" order-lg="1">
                <b-button :to="{
                    name: 'tournament',
                    query: {
                    id: id,
                    code: tm_params.code,
                    },
                }" class="tourBtn">TOURNAMENT INFORMATION</b-button>
                </b-col>
                <b-col cols="12" lg="5" md="5" sm="12" order="1" order-sm="1" order-md="1" order-lg="2" >
                <b-img class="courseLogo" :src="tm_params.sponsor_logo"></b-img>
                </b-col>
            </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  name: "scheduleInfo",
  props: ["id", "fields", "data", "loggedin", "course", 'idName', 'code', 'tm_params', 'changeYear', 'fullData' , 'PlayerLogged', 'DEFIMAGESAMS3'],
  data() {
    return {
    };
  },

};
</script>

<style scoped>
.row.row-cols-yellowouterblock {
  height: 20px;
  background-color: #F2AF14; 
  border-radius: 10px 10px 0px 0px;
}
.yellowBand {
  height: 20px;
  background-color: #F2AF14;
}
.sectionBackground {
  background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  background-repeat: no-repeat;
  padding-bottom: 7em;
  padding-top: 5em;
  background-size: cover;
}
.upcomingtourn {
  color: #fff;
  background-color: #214232;
  width: 300px;
  text-align: center;
  padding: 10px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 10pt;
  border-radius: 0px 0 10px 10px;
  margin-left: -15px;
}
.bottomsection-info {
  margin-top: 30px;
}
.memberButtons{
  /* margin-top: 5px;
  margin-left: -40px; */
}
.dateandtime {
  /* padding-left: 55px; */
}
.tourCard {
  background-color: white;
  border-radius: 10px 0px 0px 0px;
}
.otherInfo {
  color: #939393;
  margin-bottom: 0;
  text-align: left;
  font-size: 9pt;
}
.otherValue {
  color: #F2AF14;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
}
.memLink{
  color: #fff!important;
  margin: 0px 2px;
}
.tourBtn {
  display: block;
  /* margin: auto; */
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 11px;
  margin-top: 30px;
  height: 35px;
  line-height: 26px!important;
}
.tourBtn:hover {
  display: block;
  /* margin: auto; */
  background: #204232;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 400;
  margin-top: 30px;
  height: 35px;
  line-height: 26px!important;
}
.tourDate {
  color: #f2af15;
  font-size: 13px;
  margin-bottom: 30px;
  margin-top: 25px;
  text-transform: capitalize;
}
.tourCourse {
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
}
.FullName {
}
.tourCourseTwo {
  color: #939393;
  font-size: 9pt;
  font-weight: 100;
  margin-top: 25px;
  display: block;
  font-weight: bold;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  height: 0;
}
.over {
  overflow: hidden;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}
.courseImg {
  width: 100%;
  /* min-height: 352px; */
  min-height: 375px;
  border-radius: 0px 0px 0px 0px;
  min-width: 1050px;
}
.courseLogo {
  max-height: 120px;
  /* width: 70px; */
  /* width: 120px; */
  width: 180px;
  margin-top: -30px;
  float: right;
}
div#text-field-container {
  position: relative;
  top: 10px;
  display: block !important;
  line-height: 42px;
}
.Findatourn {
  width: 100%;
  line-height: 33px;
  background-color: white;
  border: 1px solid black;
  padding-left: 10px;
}
input.Findatourn::placeholder {
  color: #aaa !important;
  padding-left: 10px;
  display: block;
  line-height: 34px;
  margin-top: 15px;
  position: relative;
  top: 5px;
  font-size: 11pt;
}
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 10pt;
  color: #000 !important;
}
.schedTour-btn {
  flex: 0 0 19.666667%;
  max-width: 19.666667%;
}
li {
  list-style-type: none;
}
::v-deep .nav-link {
  color: black !important;
  width: 100%;
}
.title {
  padding: 50px 0;
  color: #026034;
  font-weight: 700;
  font-size: 18pt;
}
nav.card-header.dropdown-seasons {
  padding-left: 30px;
  background: none;
  border: none;
  border-top: 0px;
}
::v-deep th {
  color: white;
  background-color: #263056;
}
::v-deep tr:nth-child(even) {
  background-color: #dfe1e6;
}
.tRow {
  border-bottom: 1px solid #000;
  padding: 5px 0;
  background: white;
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 700;
  line-height: 2;
  position: relative;
  top: 3px;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
}
.menuLink > a {
  padding: 4px 0 0 3em;
  text-transform: capitalize;;
  font-size: 10pt;
  font-weight: 500;
}
.tourLogo {
  max-width: 80px !important;
  margin-top: -3px;
  padding-left: 0;
  margin-left: 15px;
  max-height: 34px !important;
  width: initial !important;
}
@media only screen and (max-width: 500px) {
  ::v-deep .tourTitleMore {
    color: #6C6C6C;
    font-weight: 700;
    font-size: 15px;
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -13px;
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .mobileTop {
    padding-top: 20px!important;
  }
  ::v-deep .btn-group, .btn-group-vertical {
    width: 100%;
  }
  .courseimage-hide {
    max-height: 200px;
  }
  .sectionBackground {
    background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
    background-repeat: no-repeat;
    padding: 1em;
    background-size: cover;
  }
  .courseLogo {
    width: 150px;
    margin-top: -30px;
    float: right;
    position: absolute;
    top: -3em;
    right: 0;
  }
  .tourBtn {
    display: block;
    /* margin: auto; */
    background: #006737;
    border: none;
    color: #fff!important;
    border-radius: 0;
    padding: 10px 20px;
    width: 100%;
    text-transform: capitalize;
    text-decoration: none !important;
    font-weight: 400;
    font-size: 11px;
    margin-top: 30px;
    height: 35px;
    line-height: 17px!important;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
  .mobButton {
    padding: 5px 0;
  }
  .schImg {
    height: 160px;
  }
}

@media only screen and (max-width: 990px) {
  .dateandtime {
    text-align: left;
  }
  .over {
    overflow: hidden;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
  }
  .courseImg {
    width: 100%;
    min-height: 352px;
    border-radius: 0px 0px 0px 0px;
    min-width: 1050px;
  }
  .courseimage-hide {
    /* display: none; */
  }
  .tourCard {
    text-align: center;
  }
  .bottomsection-info {
    display: block;
  }
  .infoPad {
    margin-bottom: 20px;
  }
}
</style>